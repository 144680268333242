<div class="bg-blue-100">
  <div>
    <main class="max-w-7xl px-4">
        <!-- <div class="alert alert-info alert-dismissible fade show" role="alert">
    <p> We are in the process of adding more constituencies candidates . Please bear with the delays. Please come back
      after a day or two and we may have added your constituencies candidate details. If you want to contribute for
      this, please send an email to goin3it@gmail.com.
    </p>
    <p>
      Please let us know the details of any incorrect information, omissions by sending an email to goin3it@gmail.com
      with the constituency name, candidate name and the description of what is incorrectly displayed with correct
      information. We will try fixing it as early as possible.
    </p>

    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

<!--        <div class="media-body ml-0">
	<a href="https://goin2it-com.translate.goog/?_x_tr_sch=http&_x_tr_sl=en&_x_tr_tl=ta&_x_tr_hl=en&_x_tr_pto=wapp" target="blank">தமிழில் பார்க்க...	
	</a>
  </div>
  <div class="media-body ml-0">
	<a href="https://goin2it-com.translate.goog/?_x_tr_sch=http&_x_tr_sl=en&_x_tr_tl=hi&_x_tr_hl=en&_x_tr_pto=wapp" target="blank">हिंदी में देखने के लिए
	</a> 
  </div>-->

              <label
                class="text-gray-900 text-sm font-bold mb-2"    for="election"
              >
                Choose Election
              </label>
              <div class="relative">
                <select
                  class="appearance-none bg-green-100 text-sm font-bold mb-2 border border-gray-200 text-indigo-500 py-2 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="election"
                  (change)="onElectionSelect($event)"
                >
                  <option
                    *ngFor="let election of _electionList"
                    [value]="election.value"
                  >
                    {{ election.text }}
                  </option>
                </select>
			</div>
			  <label class="text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Parliament'"
                  >Choose Nation</label
                >
              <label
                class="text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Assembly'"
                for="state"
              >
                Choose State
              </label>
              <div class="relative">
                <select
                  class="block appearance-none text-sm font-bold mb-2 bg-green-100 border border-gray-200 text-indigo-500 py-2 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="state"
                  (change)="onStateSelect($event)"
                >
                  <option
                    *ngFor="let state of _stateList"
                    [value]="state.value"
                  >
                    {{ state.text }}
                  </option>
                </select>
              </div>
			<div class="col-span-1">
             <label class="block text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Parliament'"
                  >Choose State</label
                >
              <label
                class="block text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Assembly'"
                for="state"
              >
                Choose District
              </label>
              <div class="relative">
                <select
                  class="block appearance-none bg-green-100 text-sm font-bold mb-2  border border-gray-200 text-indigo-500 py-2 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="district"
                  (change)="onDistrictSelect($event)"
                >
                  <option
                    *ngFor="let district of _districtList"
                    [value]="district.value"
                  >
                    {{ district.text }}
                  </option>
                </select>               
              </div>
            </div>
			
			<div class="col-span-1">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="constituency"
              >
               Choose Constituency
              </label>
              <div class="relative">
                <select
                  class="block appearance-none bg-green-100 text-sm font-bold mb-2  border border-gray-200 text-indigo-500 py-2 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="constituency"
                  (change)="onConstituencySelect($event)"
                >
                  <option
                    *ngFor="let constituency of _constituencyList"
                    [value]="constituency.value"
                  >
                    {{ constituency.text }}
                  </option>
                </select>                
              </div>
            </div>
			
 <!--<div class="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
        <div class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <div class="grid grid-cols-4 gap-4">
            <div class="col-span-1">
              <label
                class="block text-gray-900 text-sm font-bold mb-2"
                for="election"
              >
                Election
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-green-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="election"
                  (change)="onElectionSelect($event)"
                >
                  <option
                    *ngFor="let election of _electionList"
                    [value]="election.value"
                  >
                    {{ election.text }}
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 12l-6-6h12l-6 6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-span-1">
			 <label class="block text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Parliament'"
                  >Nation</label
                >
              <label
                class="block text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Assembly'"
                for="state"
              >
                State
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-green-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="state"
                  (change)="onStateSelect($event)"
                >
                  <option
                    *ngFor="let state of _stateList"
                    [value]="state.value"
                  >
                    {{ state.text }}
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 12l-6-6h12l-6 6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-span-1">
             <label class="block text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Parliament'"
                  >State</label
                >
              <label
                class="block text-gray-900 text-sm font-bold mb-2" *ngIf="_constituencyType == 'Assembly'"
                for="state"
              >
                District
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-green-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="district"
                  (change)="onDistrictSelect($event)"
                >
                  <option
                    *ngFor="let district of _districtList"
                    [value]="district.value"
                  >
                    {{ district.text }}
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 12l-6-6h12l-6 6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-span-1">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="constituency"
              >
                Constituency
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-green-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-green-100 focus:border-gray-500"
                  id="constituency"
                  (change)="onConstituencySelect($event)"
                >
                  <option
                    *ngFor="let constituency of _constituencyList"
                    [value]="constituency.value"
                  >
                    {{ constituency.text }}
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 12l-6-6h12l-6 6z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
		

      </div>-->

      <section aria-labelledby="products-heading" class="pb-24 pt-6">
        <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
		
		<div class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
		  <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
          <!-- Filters -->
          <form class="lg:block" *ngIf="_candidateList.length > 0">
            <div class="relative border-b border-gray-200 py-6 bg-green">
              <h3 class="-my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button
                  type="button"
                  class="flex w-full items-center justify-between bg-green-100 py-3 text-sm text-gray-400 hover:text-gray-500"
                  aria-controls="filter-section-0"
                  data-toggle="collapse"
                  data-target="#filter-section-0"
                  aria-expanded="true"
                >
                  <span class="text-sm font-bold mb-2 text-gray-900 bg-green-100">Filters</span>
                  <span class="ml-6 flex items-center">
                    <!-- Expand icon, show/hide based on section open state. -->
                    <svg
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                      />
                    </svg>
                    <!-- Collapse icon, show/hide based on section open state. -->
                    <svg
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              <div class="pt-6 collapse visible" id="filter-section-0">
                <label class="text-gray-700 bg-green-100" *ngIf="_availableSex.length > 0"
                  >Gender</label
                >
                <div class="space-y-6">
                  <ng-container *ngFor="let sex of _availableSex">
                    <div *ngIf="sex == 'male'" class="flex items-center">
                      <input
                        id="sexOption1"
                        type="checkbox"
                        [(ngModel)]="_filters.sex.male"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="sexOption1"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >Male</label
                      >
                    </div>
                    <div *ngIf="sex == 'female'" class="flex items-center">
                      <input
                        id="sexOption2"
                        type="checkbox"
                        [(ngModel)]="_filters.sex.female"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="sexOption2"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >Female</label
                      >
                    </div>
                    <div *ngIf="sex == 'other'" class="flex items-center">
                      <input
                        id="sexOption3"
                        type="checkbox"
                        [(ngModel)]="_filters.sex.other"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="sexOption3"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >Other</label
                      >
                    </div>
                  </ng-container>

                  <label *ngIf="_availableTaxFilled.length > 0"
                    >Tax Filled</label
                  >
                  <ng-container *ngFor="let taxFilled of _availableTaxFilled">
                    <div *ngIf="taxFilled == 'yes'" class="flex items-center">
                      <input
                        id="taxFilledOption1"
                        type="checkbox"
                        [(ngModel)]="_filters.taxFilled.yes"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="taxFilledOption1"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >Yes</label
                      >
                    </div>
                    <div *ngIf="taxFilled == 'no'" class="flex items-center">
                      <input
                        id="taxFilledOption2"
                        type="checkbox"
                        [(ngModel)]="_filters.taxFilled.no"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="taxFilledOption2"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >No</label
                      >
                    </div>
                  </ng-container>
				<!--
                  <label *ngIf="_availableCase.length > 0">Case</label>
                  <ng-container *ngFor="let case of _availableCase">
                    <div
                      *ngIf="case == 'pendingCase'"
                      class="flex items-center"
                    >
                      <input
                        id="caseOption1"
                        type="checkbox"
                        [(ngModel)]="_filters.case.pendingCase"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="caseOption1"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >Pending Case</label
                      >
                    </div>
                    <div
                      *ngIf="case == 'convictedCase'"
                      class="flex items-center"
                    >
                      <input
                        id="caseOption2"
                        type="checkbox"
                        [(ngModel)]="_filters.case.convictedCase"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="applyFilter()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        for="caseOption2"
                        class="ml-3 min-w-0 flex-1 text-gray-900"
                        >Convicted Case</label
                      >
                    </div>
                  </ng-container>-->
                </div>
              </div>
            </div>
            <div class="border-b border-gray-200 py-6">
              <h3 class="-my-3 flow-root">
                <!-- Expand/collapse section button -->
                <button
                  type="button"
                  class="flex w-full items-center justify-between bg-green-100 py-3 text-sm text-gray-400 hover:text-gray-500"
                  data-target="#filter-section-1"
                  aria-controls="filter-section-1"
                  aria-expanded="true"
                  data-toggle="collapse"
                >
                  <span class="text-sm font-bold mb-2 text-gray-900">Sort By</span>
                  <span class="ml-6 flex items-center">
                    <!-- Expand icon, show/hide based on section open state. -->
                    <svg
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                      />
                    </svg>
                    <!-- Collapse icon, show/hide based on section open state. -->
                    <svg
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </h3>
              <!-- Filter section, show/hide based on section state. -->
              <div
                class="pt-6 space-y-6 collapse visible"
                id="filter-section-1"
              >
                <div class="flex items-center">
                  <select
                    (change)="onSortOrder($event)"
                    name="sort"
                    class="relative w-full cursor-default rounded-md bg-green-100 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  >
                    <option value="ascending">Ascending</option>
                    <option value="descending">Descending</option>
                  </select>
                </div>
                <div class="flex items-center">
                  <select
                    (change)="onSortColumn($event)"
                    name="type"
                    class="relative w-full cursor-default rounded-md bg-green-100 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  >
                    <option value="age">Age</option>
                    <option value="candidateTotalIncome">Total Income</option>
                    <option value="candidateTotalWealth">Total Wealth</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
		  </div>
		  </div>

          <div class="lg:col-span-3 ">
            <div
              class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2"
              *ngIf="_filterCandidateList.length > 0"
            >
			  <div
                *ngFor="let candidate of _filterCandidateList"
                class="col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200"
              >
			    <div class="flex-1 flex flex-col p-8 bg-green-100">
                  <img
                    class="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                    src="{{ candidate.eciPhotoLink }}"
                    alt=""
                  />
                  
                  <dl class="mt-1 flex-grow flex flex-col">
                    <div class="grid grid-cols-2 gap-4 mt-4">
					<div class="text-sm">
                    <a
                      href="{{ candidate.officialProfileLink }}"
                      target="_blank"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                      >ECI Candidate link</a
                    >
                  </div>
                  <div
                    *ngIf="candidate.mynetaCandidateUrl"
                    class="mt-1 text-sm"
                  >
                    <a
                      href="{{ candidate.mynetaCandidateUrl }}"
                      target="_blank"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                      >MyNeta candidate link</a
                    >
                  </div>
                  <div
                    *ngIf="!candidate.mynetaCandidateUrl"
                    class="mt-1 text-sm"
                  >
                    <a
                      href="https://www.myneta.info/LokSabha2019/"
                      target="_blank"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                      >MyNeta All Candidate link</a
                    >
                  </div>
                      <div class="text-left" >
					  <dt>Name</dt>
					<h3 class="text-gray-900 text-sm">
                    {{ candidate.name | titlecase }}
                  </h3>
                        <dt>Education</dt>
                        <dd class="text-gray-900 text-sm">
                          {{ candidate.highestEducationLevel | titlecase }}
                        </dd>
                        <dt>Total Wealth</dt>
                        <dd class="text-gray-900 text-sm">
                          {{
                            candidate.candidateTotalWealth.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0
                              }
                            )
                          }}
                        </dd>
                        <dt>Pending Case</dt>
                        <dd
                          class="text-gray-900 text-sm"
                          *ngIf="!candidate.pendingCases"
                        >
                          No
                        </dd>
                        <dd
                          class="text-gray-900 text-sm"
                          *ngIf="candidate.pendingCases"
                        >
                          Yes
                        </dd>

                        <div *ngIf="candidate.email">
                          <dt>Email</dt>
                          <dd class="text-gray-900 text-sm">
                            {{ candidate.email }}
                          </dd>
                        </div>
                        <div *ngIf="candidate.telephone">
                          <dt>Phone</dt>
                          <dd class="text-gray-900 text-sm">
                            {{ candidate.telephone }}
                          </dd>
                        </div>
                      </div>
                      <div class="text-left">
					  <dt>Age</dt>
                    <dd class="text-gray-900 text-sm">{{ candidate.age }}</dd>
					<dt>Party</dt>
                    <dd class="text-gray-900 text-sm">
                       {{ candidate.party }}
                    </dd>
                        <dt>Profession</dt>
                        <dd class="text-gray-900 text-sm">
                          {{ candidate.selfProfession | titlecase }}
                        </dd>
                        <dt>Income</dt>
                        <dd class="text-gray-900 text-sm">
                          {{
                            candidate.candidateTotalIncome.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0
                              }
                            )
                          }}
                        </dd>
                        <dt>Convicted Case</dt>
                        <dd
                          class="text-gray-900 text-sm"
                          *ngIf="!candidate.convictedCases"
                        >
                          No
                        </dd>
                        <dd
                          class="text-gray-900 text-sm"
                          *ngIf="candidate.convictedCases"
                        >
                          Yes
                        </dd>
                        <div *ngIf="candidate.facebookId">
                          <dd class="font-medium text-indigo-600 hover:text-indigo-500">
                            <a href="{{ candidate.facebookId }}" target="_blank"
                              >Facebook link</a
                            >
                          </dd>
                        </div>
                        <div *ngIf="candidate.whatsappId">
                          <dd class="font-medium text-indigo-600 hover:text-indigo-500">
                            <a href="{{ candidate.whatsappId }}" target="_blank"
                              >WhatsApp link</a
                            >
                          </dd>
                        </div>
                        <div *ngIf="candidate.twitterId">
                          <dd class="font-medium text-indigo-600 hover:text-indigo-500">
                            <a href="{{ candidate.twitterId }}" target="_blank"
                              >Twitter link</a
                            >
                          </dd>
                        </div>
                        <div *ngIf="candidate.instagramId">
                          <dd class="font-medium text-indigo-600 hover:text-indigo-500">
                            <a href="{{ candidate.instagramId }}" target="_blank"
                              >Instagram link</a
                            >
                          </dd>
                        </div>
                      </div>
                    </div>
                  </dl>
                  <a
                    href="detail/{{ _selectedConstituency }}/{{
                      candidate.candidateId
                    }}"
                    target="_blank"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                    >View More</a
                  >
                </div>
                
              </div>

              <div class="row mb-4" *ngIf="_filterCandidateList.length == 0">
                No Candidate Details Available
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</div>

<app-disclaimer> </app-disclaimer>
