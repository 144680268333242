<footer class=" bg-blue-500 text-white">
  <div class="container py-4">
    <div class="row py-5">
      <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
        <div class="d-flex align-items-center mb-3">
          <img src="img/logo-footer.svg" alt="" width="30">
          <span class="font-bold text-2xl ml-2">s/Elect</span>
        </div>
        <p class="text-sm italic mb-3">Your next 5 years in 5 minutes.</p>
      </div>
      <div class="col-md-4 col-sm-6 mb-3 mb-md-0">
        <h6 class="pt-2 text-white">Useful links</h6>
        <div class="d-flex flex-wrap">
          <ul class="list-unstyled text-gray-300 mb-0 mb-3 mr-4">
            <li><a class="text-sm" href="#">About us</a></li>
            <li><a class="text-sm" href="#">Write to us</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-700 py-4">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-6 text-lg-left mb-2 mb-md-0">
          <p class="mb-0 text-sm">&copy; 2024 All rights reserved.</p>
        </div>
        <div class="col-md-6 col-sm-6 text-md-right">
          <!-- <p class="mb-0 text-muted mb-0 text-small">Template designed by <a class="text-blue-500" href="https://bootstraptemple.com/p/listings">Bootstrap Temple</a>.</p> -->
          <!-- If you want to remove the backlink, please purchase the Attribution-Free License.-->
        </div>
      </div>
    </div>
  </div>
</footer>