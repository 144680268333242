<div id="disclaimer" class="modal" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content bg-gray-100">
            <div class="modal-header bg-blue-500 text-white">
                <h5 class="modal-title font-bold text-lg">Welcome</h5>
            </div>
            <div class="modal-body text-gray-700">
                <p class="font-semibold">Friends,</p>
                <p> Welcome to our <span class="font-bold text-blue-500">s/Elect!</span> portal where every candidate is equal.
                </p>
                <p>
                    All the information shown here are taken from the <a href="http://affidavit.eci.gov.in/" target="blank" class="text-blue-500 hover:text-blue-700"><img src="https://affidavit.eci.gov.in/img/logo/eci-logo.png" height="40px" width="80px" class="py-2" alt="ECI">Election Commision of India's candidate affidavit</a> website which is used
                    for displaying the candidate affidavits for general
                    public. We also captured the affidavit details provided by <a href="https://myneta.info/" class="text-blue-500 hover:text-blue-700"><img src="https://myneta.info/lib/img/mynetanewlogo.png" width="80px" height="40px" class="py-2" alt="MyNeta">myneta</a> portal. 
                </p>
                <p>
                    If you want to know more details, Please click the candidate's pages links available as part
                    of each candidate.
                </p>
                <p>
                    While we have taken every precaution to ensure that the data displayed here is accurate, errors can
                    occur. We, assume no responsibility or liability for any errors or omissions in the content of this
                    site.
                </p>
                <input type="checkbox" id="chkAgree" name="chkAgree" value="chkAgree" (click)="chkAgree($event)">
                <label for="chkAgree" class="ml-2">I understand and agree</label><br>
                <div class="modal-footer">
                    <button id="btnAgree" type="button" [disabled]="!_disClaimerAccepted" (click)="clickAgree()" class="btn btn-secondary bg-blue-500 text-white hover:bg-blue-700">Agree</button>
                </div>
            </div>
        </div>
    </div>
</div>