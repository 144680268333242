<header class="header bg-blue-500 text-white">
  <nav class="navbar navbar-expand-lg py-3 py-lg-2">
    <div class="container">
      <div>
        <span class="font-bold text-4xl ml-2 mb-0">s/Elect</span>
        <p class="text-sm italic">Your next 5 years in 5 minutes.</p>
      </div>

      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto text-lg">
          <li class="nav-item">
            <!-- Navbar link--><a
              class="nav-link text-white hover:text-gray-300"
              href="detail.html"
              >About Us</a
            >
          </li>
          <!-- <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" id="pages" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages</a>
                <div class="dropdown-menu mt-lg-3"><a class="dropdown-item" href="index.html">Home</a><a class="dropdown-item" href="category.html">Category</a><a class="dropdown-item" href="detail.html">Detail</a></div>
              </li>
              <li class="nav-item ml-lg-2 py-2 py-lg-0"><a class="btn btn-primary" href="#listingModal" data-toggle="modal">Add listing</a></li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>
